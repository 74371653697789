import React from 'react'

function ContentGraphic() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="242.8" height="176.03">
			<defs>
				<path
					d="M242.8 149c0 14.93-54.35 27.04-121.4 27.04C54.35 176.04 0 163.93 0 149c0-14.94 54.35-27.04 121.4-27.04 67.05 0 121.4 12.1 121.4 27.04z"
					id="a"
				/>
				<path d="M165.84 119.3h-2.56l-.28-.44-.82-1.27 10.25-55.41 1.56-.65 3.69-1.54-11.84 59.31z" id="b" />
				<path d="M163 118.86l-.82-1.27 10.25-55.41 1.56-.65L163 118.86z" id="c" />
				<path d="M192.45 141.76l-.74.24-.97-2.2-16.75-76.4 1.74-.4 3.66-.82 16.11 78.6-3.05.98z" id="d" />
				<path d="M191.71 142l-.97-2.2-16.75-76.4 1.74-.4 16.72 78.76-.74.24z" id="e" />
				<path d="M47.71 136.88l-3.08.68-.22.05-.85-1.59 1.7-75.3 1.65-.23 3.48-.5-2.68 76.89z" id="f" />
				<path d="M44.63 137.56l-.22.05-.85-1.59 1.7-75.3 1.65-.23-2.28 77.07z" id="g" />
				<path d="M63.19 164.64l-.35.06-.98-2.07-15.5-100.45.45-.13 2.36-.72 17.21 102.76-3.19.55z" id="h" />
				<path d="M62.84 164.7l-.98-2.07-15.5-100.45.45-.13 16.38 102.59-.35.06z" id="i" />
				<path
					d="M133.74 53.76c7.12 9.09 11.07 14.14 11.86 15.15.82 1.05 2.14 1.6 3.47 1.44 4.86-.57 43.78-5.12 48.65-5.69 1.93-.23 3.39-1.87 3.39-3.82V49.61l-67.37 4.15z"
					id="j"
				/>
				<path d="M176.95 56.2v4.76c0 .68-.55 1.22-1.23 1.22h-.84c-.68 0-1.23-.54-1.23-1.22V56.2h3.3z" id="k" />
				<path
					d="M46.36 63.41c7.11 9.08 11.06 14.13 11.85 15.14.83 1.05 2.15 1.6 3.48 1.45 4.86-.57 43.78-5.13 48.64-5.7 1.94-.23 3.4-1.87 3.4-3.82V59.26l-67.37 4.15z"
					id="l"
				/>
				<path d="M89.57 65.85v4.75c0 .68-.55 1.23-1.23 1.23h-.85c-.67 0-1.22-.55-1.22-1.23v-4.75h3.3z" id="m" />
				<path
					d="M166.84 34l.42.02.43.02.42.02.42.04.43.03.42.05.42.05.42.05.42.07.42.06.41.08.42.08.41.08.42.09.41.1.41.11.41.1.41.12.41.12.4.13.41.13.4.14.4.14.4.15.39.15.4.17.39.16.39.17.39.18.38.19.39.19 25.88 13.01.05.03.05.02.04.03.05.03.04.03.04.03.05.03.04.03.04.03.04.04.04.03.04.04.03.04.04.03.04.04.03.04.03.04.04.04.03.04.03.05.03.04.03.04.02.05.03.04.02.05.03.04.02.05.02.05.02.05.02.05.02.04.02.05.01.05.02.06.01.05.01.05.01.05.01.05.01.06.01.05.01.09v.25l-.01.09v.08l-.02.09-.01.08-.02.08-.02.08-.03.08-.03.08-.03.07-.03.08-.04.07-.03.07-.05.07-.04.07-.05.07-.05.07-.05.06-.05.06-.06.06-.06.06-.06.05-.06.05-.07.05-.07.05-.07.04-.07.05-.07.04-.07.03-.08.04-.08.03-.08.03-.08.02-.08.02-.09.02-.08.02-.09.01-144.7 16.85-.34.04-.33.03-.34.01-.33.02h-.33l-.34-.01-.33-.01-.33-.02-.33-.02-.33-.04-.33-.04-.33-.05-.32-.05-.32-.07-.33-.07-.32-.08-.31-.08-.32-.1-.31-.1-.31-.1-.31-.12-.31-.12-.3-.12-.3-.14-.3-.14-.29-.15-.29-.15-.29-.17-.28-.16-.28-.18-.28-.18-.27-.19-.27-.19-.27-.2-.26-.21-.26-.21-.25-.22-.25-.23-.24-.23-.24-.24-14.97-15.36-.03-.03-.02-.03-.03-.03-.03-.03-.02-.03-.03-.04-.03-.03-.02-.03-.02-.03-.03-.04-.02-.03-.02-.04-.03-.03-.02-.04-.02-.03-.02-.04-.02-.03-.02-.04-.01-.04-.02-.03-.02-.04-.02-.04-.01-.04-.02-.03-.01-.04-.02-.04-.01-.04-.01-.04-.01-.04-.01-.04-.02-.04-.01-.04v-.04l-.01-.04-.01-.04-.01-.04-.01-.04v-.04l-.01-.04v-.04l-.01-.1V49.56l.01-.1.01-.1.01-.09.02-.1.02-.09.03-.09.03-.1.03-.08.04-.09.04-.09.04-.08.05-.09.05-.08.05-.08.06-.07.05-.08.06-.07.07-.07.06-.07.07-.06.08-.06.07-.06.08-.06.07-.05.08-.06.09-.04.08-.05.09-.04.09-.04.09-.03.09-.03.1-.03.09-.03.1-.02.1-.01.1-.01 128.56-13.11.42-.04.43-.04.42-.03.43-.02.43-.01.42-.01.43-.01h.42l.43.01z"
					id="n"
				/>
				<path
					d="M204.9 63.04s-10.95 6.17-9.53 31.59c.07 1.15.42 2.25 1.02 3.23 2.08 3.42 6.53 12.07-.89 13.14v1.96s13.42-6.72 14.89-12.45c0 0 6.34-37.59 8.78-40.52 0 0 4.03-7.57-.12-8.91 0 0 5.98 3.9-14.15 11.96z"
					id="o"
				/>
				<path
					d="M192.45 112.96c-2.08.24-28.68-3.91-41.62-6.72-8.71-1.89-9.84-4.06-9.65-5.27 5.73 1.96 24.33 8.18 31.01 8.69 7.93.61 23.09 2.31 24.23 1.15.45-.44.9.03 1.29.77-1.7.64-3.47 1.1-5.26 1.38z"
					id="p"
				/>
				<path
					d="M150.83 106.24h-.03l-.09-.02-.15-.03-.2-.04-.25-.03-.29-.04-.33-.03-.36-.03-.39-.02h-.42l-.43.01-.46.03-.46.05-.47.08-.47.1-.48.14-.46.17-.46.2-.43.25-.42.29-.4.34-.37.38-.33.44-.3.5-.25.55-.21.62-11.71 52.23h-2.2l11.59-52.96.01-.03.03-.07.05-.11.06-.15.09-.19.1-.21.13-.24.14-.26.16-.29.19-.29.2-.31.23-.31.25-.32.26-.32.29-.31.31-.3.33-.29.36-.27.37-.25.4-.23.42-.2.45-.16.47-.13.49-.09.51-.05.54.01 4.39 2.19z"
					id="q"
				/>
				<path d="M197.69 114.91l2.45-2.32-6.35-.86h-7.08l10.98 3.18z" id="r" />
				<path
					d="M90.85 49.37l38.93-3.78c-.93-15.67-1.45-24.38-1.55-26.12-.09-1.45-1.33-2.55-2.78-2.46h-.06c-3.39.29-30.53 2.57-33.92 2.85-1.43.12-2.5 1.37-2.4 2.79.23 3.57.83 12.47 1.78 26.72z"
					id="s"
				/>
				<path d="M104.03 57.51l-1.47.16-11.35-7.32v-1.59l38.57-3.47 13.06 7.99-38.81 4.23z" id="t" />
				<path d="M102.56 57.67l-11.35-7.32v-1.42l12.82 8.58-1.47.16z" id="u" />
				<path d="M93.04 46.2l34.18-3.18L126 19.84 91.7 22.4l1.34 23.8z" id="v" />
				<path d="M116.23 55.59l12.57-1.22-4.27-2.68-12.45 1.1 4.15 2.8z" id="w" />
				<path d="M100.37 53.28l33.56-3.18-5.98-3.9-32.83 3.17 5.25 3.91z" id="x" />
				<path d="M154.67 13.31h24.78v24.77h-24.78V13.31z" id="y" />
				<path d="M154.67 13.31h24.78v24.77h-24.78V13.31z" id="z" />
				<path
					d="M163.21 35.98h12.52c.45-.36 4.06-3.27 4.51-3.64 0 0-10.19-2.91-8.73-9.61l-8.3 2.04s3.49 9.47 0 11.21z"
					id="A"
				/>
				<path
					d="M163.21 35.98h12.52c.45-.36 4.06-3.27 4.51-3.64 0 0-10.19-2.91-8.73-9.61l-8.3 2.04s3.49 9.47 0 11.21z"
					id="B"
				/>
				<path
					d="M145.44 143.31s-1.02 8.3-5.39 10.05c0 0-5.53 4.95-1.74 4.8 0 0 8.73-1.16 9.46-2.47 0 0 2.48-7.58 4.95-8.45 0 0 7.14-4.22 3.79-8.3 0 0-11.79-19.1-11.14-20.62l-10.85 1.4s13.11 18.49 10.92 23.59z"
					id="C"
				/>
				<path
					d="M161.9 83.17s-27.09 1.74-34.08 8.3c0 0 6.12 31.01 7.57 31.31 0 0 12.82-.88 12.38-4.08l-8.15-16.31s10.04-3.64 15.58-4.52l11.65-4.95-4.95-9.75z"
					id="D"
				/>
				<path
					d="M149.52 79.09s.44 16.16 4.51 16.75V83.46c-.01-.33-.06-1.98-.14-4.95-2.33.31-3.79.5-4.37.58z"
					id="E"
				/>
				<path
					d="M161.9 36.71s-7.58-2.18-9.03 3.64l-1.9 25.92c-.26 1.61-2.35 14.42-2.62 16.02 0 0 4.81-1.45 5.83-.73.41-.51 2.44-3.08 6.11-7.71.86-19.81 1.39-32.19 1.61-37.14z"
					id="F"
				/>
				<path
					d="M161.9 36.71s-7.58-2.18-9.03 3.64l-1.9 25.92c-.26 1.61-2.35 14.42-2.62 16.02 0 0 4.81-1.45 5.83-.73.41-.51 2.44-3.08 6.11-7.71.86-19.81 1.39-32.19 1.61-37.14z"
					id="G"
				/>
				<path
					d="M180.39 132.68s5.97-2.04 7.43 3.93c0 0-.29 4.37 1.45 7.86 0 0 2.04 9.61-1.31 11.07 0 0-6.4 1.6-6.55 0 0 0 2.91-1.6-.58-7.28 0 0-10.92-16.02-13.98-17.04l5.39-8.4s5.09 10 8.15 9.86z"
					id="H"
				/>
				<path
					d="M185.49 77.2s10.48 17.47 3.64 22.57l-27.23 14.85s10.92 7.43 11.5 9.9c0 0-5.24 9.18-7.28 9.32 0 0-8.01-6.7-14.42-8.44 0 0-10.34-14.27-3.35-18.93l13.55-12.38c-.14-.75-.82-4.49-2.04-11.21 13.67-3.03 22.21-4.93 25.63-5.68z"
					id="I"
				/>
				<path
					d="M163.86 30.23c-6.19.01-11.22-5-11.23-11.19-.01-6.2 5-11.22 11.2-11.23h.03c6.19 0 12.54 4.92 12.54 11.11 0 6.19-6.35 11.31-12.54 11.31z"
					id="J"
				/>
				<path
					d="M176.75 30.16s5.82 1.31 7.13 2.47c0 0 2.04 1.02 2.92 3.35l-4.37 24.03s2.91 16.75 4.51 18.35l-12.96 3.93s-9.61 1.75-11.21 2.48c0 0-4.51 1.75-3.93 0 0 0 3.06-3.06-1.17-5.24 0 0 1.46-7.14 0-10.34-1.45-3.21-8.15-14.86-2.76-23.3 5.38-8.45 7.86-11.51 7.86-11.51s1.89 1.6 3.93.73c0 0 10.05-4.37 10.05-4.95z"
					id="K"
				/>
				<path
					d="M169.91 74.87s-11.36 5.03-14.78 5.5c0 0-13.77 3.09-8.09 5.42l14.27-1.17c.81-.34 4.84-2.04 12.09-5.09-1.86-2.49-3.03-4.04-3.49-4.66z"
					id="L"
				/>
				<path
					d="M187.49 39.77s5.25 29.71 0 33.06l-17.33 8.73s-7.13-4.8-3.78-6.55c0 0 11.36-6.7 12.52-8.45 0 0 2.34-21.25.31-24.45l2.92-7.45s4.2-2.03 5.36 5.11z"
					id="M"
				/>
				<path
					d="M188.84 39.77s5.24 29.71 0 33.06l-17.33 8.73s-7.14-4.8-3.79-6.55c0 0 11.36-6.7 12.52-8.45 0 0-.87-19.95-2.91-23.15l4.52-10.48s5.82-.3 6.99 6.84z"
					id="N"
				/>
				<path
					d="M156.5 2.6c-.44.77-.59 1.67-.93 2.48-.93 2.25-3.2 3.6-4.84 5.4-2.14 2.34-3.2 5.88-1.94 8.79.48 1.13 1.28 2.1 1.88 3.17 1.68 2.99 1.77 6.59 1.81 10.02l.1 6.74c.01 1.03.02 2.08-.29 3.06s-1 1.9-1.98 2.21c2.23.78 4.7-.07 5.99-2.04.27 1.72-.33 3.47-1.61 4.66 2.67-.63 4.91-2.57 6.32-4.92 1.41-2.35 2.07-5.09 2.36-7.82.15-1.44.21-2.93-.25-4.31-.32-.97-.88-1.84-1.41-2.71-1.37-2.25-2.6-4.64-3.05-7.24-.44-2.6.01-5.45 1.69-7.47 1.31-1.58 3.28-2.48 5.33-2.44 2.07.08 3.94 1.22 4.95 3.03.39.73.94 1.72 1.73 1.47.15-.07.29-.14.43-.21.88-.41 1.88.39 2.27 1.27 1.22 2.79-1.03 5.85-1.27 8.89-.21 2.83 1.36 5.49 3.25 7.62 3.26 3.64 7.51 6.26 12.24 7.51-.7-.17-1.32-.56-1.78-1.1 1.92.08 3.85-.08 5.73-.48-2.05-1.54-4.11-3.1-5.82-5.01-1.72-1.91-3.07-4.23-3.44-6.77-.49-3.41.78-7.1-.68-10.22-1.32-2.85-4.61-4.45-5.81-7.36-1.22-2.95-2.02-5.52-5.25-6.81-3.52-1.4-7.32-2.39-11.13-1.87-1.79.25-3.7.9-4.6 2.46z"
					id="O"
				/>
			</defs>
			<use xlinkHref="#a" fill="#f2f2f2" />
			<use xlinkHref="#a" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#b" fill="#47465a" />
			<use xlinkHref="#b" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#c" />
			<use xlinkHref="#c" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#d" fill="#47465a" />
			<use xlinkHref="#d" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#e" />
			<use xlinkHref="#e" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#f" fill="#47465a" />
			<use xlinkHref="#f" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#g" />
			<use xlinkHref="#g" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#h" fill="#47465a" />
			<use xlinkHref="#h" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#i" />
			<use xlinkHref="#i" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#j" fill="#7a799c" />
			<use xlinkHref="#j" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#k" fill="#47465a" />
			<use xlinkHref="#k" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#l" fill="#7a799c" />
			<use xlinkHref="#l" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#m" fill="#47465a" />
			<use xlinkHref="#m" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#n" fill="#47465a" />
			<use xlinkHref="#n" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#o" fill="#47465a" />
			<use xlinkHref="#o" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#p" />
			<use xlinkHref="#p" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#q" fill="#47465a" />
			<use xlinkHref="#q" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#r" fill="#47465a" />
			<use xlinkHref="#r" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#s" fill="#7a799c" />
			<use xlinkHref="#s" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#t" fill="#7a799c" />
			<use xlinkHref="#t" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#u" />
			<use xlinkHref="#u" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#v" fill="#9493b6" />
			<use xlinkHref="#v" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#w" />
			<use xlinkHref="#w" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#x" fill="#9493b6" />
			<use xlinkHref="#x" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#y" fill="#ff6584" />
			<use xlinkHref="#y" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#z" />
			<use xlinkHref="#z" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#A" fill="#fbbebe" />
			<use xlinkHref="#A" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#B" />
			<use xlinkHref="#B" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#C" fill="#fbbebe" />
			<use xlinkHref="#C" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#D" fill="#9493b6" />
			<use xlinkHref="#D" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<use xlinkHref="#E" fill="#fbbebe" />
			<use xlinkHref="#E" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			<g>
				<use xlinkHref="#F" fill="#009be5" />
				<use xlinkHref="#F" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			</g>
			<g>
				<use xlinkHref="#G" />
				<use xlinkHref="#G" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			</g>
			<g>
				<use xlinkHref="#H" fill="#fbbebe" />
				<use xlinkHref="#H" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			</g>
			<g>
				<use xlinkHref="#I" fill="#9493b6" />
				<use xlinkHref="#I" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			</g>
			<g>
				<use xlinkHref="#J" fill="#fbbebe" />
				<use xlinkHref="#J" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			</g>
			<g>
				<use xlinkHref="#K" fill="#009be5" />
				<use xlinkHref="#K" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			</g>
			<g>
				<use xlinkHref="#L" fill="#fbbebe" />
				<use xlinkHref="#L" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			</g>
			<g>
				<use xlinkHref="#M" />
				<use xlinkHref="#M" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			</g>
			<g>
				<use xlinkHref="#N" fill="#009be5" />
				<use xlinkHref="#N" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			</g>
			<g>
				<use xlinkHref="#O" fill="#ff6584" />
				<use xlinkHref="#O" fillOpacity="0" stroke="#000" strokeOpacity="0" />
			</g>
		</svg>
	)
}

export default ContentGraphic
